import { render, staticRenderFns } from "./TimeLimitsSettings.vue?vue&type=template&id=349ae3f2&scoped=true"
import script from "./TimeLimitsSettings.vue?vue&type=script&lang=ts"
export * from "./TimeLimitsSettings.vue?vue&type=script&lang=ts"
import style0 from "./TimeLimitsSettings.vue?vue&type=style&index=0&id=349ae3f2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349ae3f2",
  null
  
)

export default component.exports