
    import { defineComponent, PropType, ref } from 'vue'
import { Audience } from '@/types/GCB2'
import NotInTariffTooltip from '@root/src/components/ui/NotInTariffTooltip.vue'
import cfg from '@root/config'
import { useProject } from '@/hooks/useProject'
import api from '@api/api'
import store from '@/store'
export default defineComponent({
    props: {
        audience: {
            type: Object as PropType<Audience>,
            required: true,
        },
    },
    components: {
        NotInTariffTooltip
    },
    setup(props, {}) {
        const onCreated = () => {}

        onCreated()
        const { project } = useProject()
        const notInTariff = ref(false)
        const isExcel = props.audience.id === cfg.excelActivationId
        if (isExcel) {
            api.marketplace.getCurrentTariff(project.value.id).then( ({ data, error }) => {
                debugger
                if (error) {
                    store.dispatch('callNotify', 'Произошла ошибка при загрузке текущего тарифа')
                    return
                }
                notInTariff.value = data?.currentTariff?.integrationId === 'tariff1'
            })
        }

        return {
            notInTariff
        }
    },
})
