
import {
    defineComponent,
    PropType,
    computed,
    onMounted,
} from 'vue'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import TimeInput from '../../../inputs/TimeInput.vue'
import { EditedActivation } from '../../../../types/GCB2'
import { timezones } from '@/vars/timezones'
import { checkInputIsIntNumber } from '../../../../utils'
import DatePicker from '../../../ui/DatePicker.vue'
export default defineComponent({
    name: 'TimeLimitsSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, TimeInput, DatePicker },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
        automailValidate: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const currentTimezoneOffsetInHours = computed(() => {
            const currentDate = new Date()
            const timezoneOffsetMinutes = currentDate.getTimezoneOffset()
            const timezoneOffsetHours = (timezoneOffsetMinutes / 60) * -1
            return timezoneOffsetHours
        })

        const timezonesList = timezones.map(el => ({
            id: parseInt(el.offset.replace(/[^0-9]/g, '')),
            name: el.cities.length ? `${el.timezone} (${el.cities.slice(0, 3).join(', ')})` : el.timezone,
            recomended: currentTimezoneOffsetInHours.value === Number(el.offset),
        }))

        const formInput = (e: any) => {
            emit('validation-updated', e)
        }

        const setDefaultTime = () => {
            if (!props.value.extra.automail.sendAfter) {
                props.value.extra.automail.sendAfter = '08:00'
            }
            if (!props.value.extra.automail.sendBefore) {
                props.value.extra.automail.sendBefore = '21:00'
            }
            if (!props.value.extra.automail.timezoneOffset) {
                props.value.extra.automail.timezoneOffset = currentTimezoneOffsetInHours.value
            }
        }

        onMounted(() => {
            setDefaultTime()
        })
        return {
            formInput,
            timezonesList,
            checkInputIsIntNumber,
        }
    },
})
