var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SendMessageDialog',{attrs:{"disableGenerateButton":_vm.disableGenerateButton,"readonly":true,"item":_vm.activeItem,"type":'script'},on:{"generate-script":_vm.generateRandomScript},model:{value:(_vm.isSendMessageDialog),callback:function ($$v) {_vm.isSendMessageDialog=$$v},expression:"isSendMessageDialog"}}),_c('v-form',{ref:"form",on:{"input":_vm.formInput,"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('v-row',{staticClass:"ma-0"},[_c('div',{staticClass:"message-parts-slider"},[_c('span',{staticClass:"message-parts-slider-title"},[_vm._v("ЧАСТИ СКРИПТА")]),_c('div',{staticClass:"slider mt-4"},_vm._l((_vm.messagePartsByPartNumber),function(messageParts){return _c('drop',{key:messageParts.partNumber,staticClass:"slider-part",class:_vm.currentPartClass(messageParts),on:{"click":function($event){_vm.activePartNumber = messageParts.partNumber},"drop":function($event){return _vm.updateMessagePartPartNumber($event, messageParts.partNumber)}}},[_c('span',{staticClass:"slider-part-text",class:_vm.currentPartClass(messageParts)},[_vm._v(_vm._s(messageParts.partNumber)+" часть")])])}),1)]),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"add-activation-btns"},[_c('AddMessagePartButton',{attrs:{"messageParts":_vm.value.message.messageParts},on:{"add-message-part":_vm.addMessagePart}}),(_vm.showMessageCollection)?_c('MessageCollectionButton',{on:{"set-message":_vm.setMessage}}):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"main","small":""},on:{"click":_vm.openPreview}},[_vm._v("Предпросмотр")])],1),(
                        _vm.automailValidate.isAutomail &&
                            _vm.automailValidate.isSaveButtonClicked &&
                            _vm.value.message.messageParts.filter(el => !el._deleted).some(el => el.textVariant.trim().length < 3)
                    )?_c('p',{staticClass:"mt-4 error-message"},[_vm._v(" Укажите скрипт сообщения или удалите пустую часть ")]):_vm._e(),_c('div',{staticClass:"message-parts-editor mt-3"},_vm._l((_vm.activeMessagePartsByPartNumber),function(messagePart,i){return _c('div',{key:i},[_c('v-textarea',{ref:"message-textareas",refInFor:true,staticClass:"ml-4 mt-4",attrs:{"label":"Текст","hideDetails":true,"filled":"","error":messagePart.textVariant.trim().length < 3 &&
                                    _vm.automailValidate.isSaveButtonClicked &&
                                    _vm.automailValidate.isAutomail,"auto-grow":"","rows":"1"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex justify-center"},[_c('CustomActivatorSelect',{attrs:{"items":_vm.messageVarsList,"multiple":false,"menu-props":{ left: true }},on:{"input":function($event){return _vm.insertVar(messagePart, $event, i)}},scopedSlots:_vm._u([{key:"activator",fn:function({ selected, _id }){return [_c('v-btn',{attrs:{"id":_id,"icon":"","title":"Вставить переменную"}},[_c('v-icon',[_vm._v("mdi-variable")])],1)]}}],null,true)}),_c('v-menu',{attrs:{"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":"Эмоции"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-emoticon-outline")])],1)]}}],null,true)},[_c('SmileSelector',{on:{"input":function($event){return _vm.insertEmoji(messagePart, $event, i)}}})],1),(messagePart.textVariant)?_c('v-btn',{attrs:{"title":"Очистить","icon":""},on:{"click":function($event){messagePart.textVariant = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)]},proxy:true},{key:"prepend-inner",fn:function(){return [_c('drag',{attrs:{"data":messagePart}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-drag")])],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteMessagePart(messagePart)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]},proxy:true}],null,true),model:{value:(messagePart.textVariant),callback:function ($$v) {_vm.$set(messagePart, "textVariant", $$v)},expression:"messagePart.textVariant"}})],1)}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }